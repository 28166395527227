import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import Roots from '@/routers'
import { userInfo } from '@/store/atom'
import '@/locales'

function App() {
    const user: any = useRecoilValue(userInfo)

    useEffect(() => {
        if (!user?.code) { return }

    }, [user])


    return <Roots />
}

export default App
