import React, { useState } from 'react'
import { Button, Grid, Toast } from 'antd-mobile'

interface IOption {
    type?: string,
    id?: string,
    imgUrl?: string,
    name?: string,
    answer?: string,
    next?: string,
    nextBtn?: string,
    nextId?: string,
}

interface IData {
    type?: string;
    question?: string;
    description?: string;
    options?: IOption[];
}

interface IProps {
    data?: IData;
    onOptionChange?: Function;
    onSubmit?: Function;
}

const CardOption = (props: IProps) => {
    const { data, onOptionChange, onSubmit } = props
    const [selecedOption, setSelectedOption] = useState<any>(null)

    const onOptionClick = (op: IOption) => {
        setSelectedOption(op)
        onOptionChange?.(op)
    }

    const onSubmitClick = () => {
        if (!selecedOption) {
            Toast.show({
                content: '请选择选项',
            })
            return
        }
        onSubmit?.(selecedOption)
    }

    return (
        <div className="options">
            <div className="title">{data?.question}</div>
            <div className="description">{data?.description}</div>
            <div className="options-cont">
                <Grid columns={2} gap={20}>
                    {data?.options?.map((option: IOption) => (
                        <div
                            key={option.id}
                            className={selecedOption?.id === option.id ? 'option option-selected' : 'option'}
                            onClick={() => onOptionClick(option)}
                        >
                            <img className="option-img" src={option.imgUrl} alt="" />
                            <div className="option-name">{option.name}</div>
                        </div>
                    ))}
                </Grid>
            </div>
            <Button block onClick={onSubmitClick}>提交</Button>
        </div>
    )
}

export default CardOption