/**
 * 配置文件
 */
import frontimage from '../../assets/images/frontpage.png'
import q11image from '../../assets/images/q1-1.png'
import q12image from '../../assets/images/q1-2.png'
import q13image from '../../assets/images/q1-3.png'
import q14image from '../../assets/images/q1-4.png'
import q21image from '../../assets/images/q2-1.png'
import q22image from '../../assets/images/q2-2.png'
import q23image from '../../assets/images/q2-3.png'
import q24image from '../../assets/images/q2-4.png'
import q31image from '../../assets/images/q3-1.png'
import q32image from '../../assets/images/q3-2.png'
import q33image from '../../assets/images/q3-3.png'
import q34image from '../../assets/images/q3-4.png'
import q41image from '../../assets/images/q4-1.png'
import q42image from '../../assets/images/q4-2.png'
import q43image from '../../assets/images/q4-3.png'
import q44image from '../../assets/images/q4-4.png'
import q51image from '../../assets/images/q5-1.png'
import q52image from '../../assets/images/q5-2.png'
import q53image from '../../assets/images/q5-3.png'
import q54image from '../../assets/images/q5-4.png'
import a1image from '../../assets/images/a-friend.png'
import a2image from '../../assets/images/a-quality.png'
import a3image from '../../assets/images/a-surprise.png'
import a4image from '../../assets/images/a-cultivate.png'


export const config =  {
    'start': {
        type: 'start',
        question: '测测你是什么风格的“空间”建筑师',
        imgUrl: frontimage,
        cont: '在一所学生每天6127的县城高中里，你将负责建设一间神奇的空间，能让学生放松身心，一秒卸掉考试压力，也能让学生充电成长，对世界保持好奇。来测试一下你是什么风格的“空间”建筑师，你最匹配什么样的神奇空间吧！',
        nextBtn: '开始测试',
        nextId: '1',
    },
    '1': {
        type: 'options',
        question: '假如你建设的空间有图书借阅功能，现在你需要选一款适合阅读的椅子，你会选择？',
        options: [{
            type: 'answer',
            id: '1-1',
            pid: '1',
            imgUrl: q11image,
            name: '豆豆沙发',
            answer: '你选择的豆豆沙发成了学生最喜欢的课间休息角落，他们经常三两个人挤在一起看书聊天。',
            next: '选好座椅，一起查看一下书架吧。',
            nextBtn: '下一题',
            nextId: '2',
        }, {
            type: 'answer',
            id: '1-2',
            pid: '1',
            imgUrl: q12image,
            name: '人体工学椅',
            answer: '你选择的人体工学座椅虽然价格昂贵，但广受好评，大家很期待一个配得上座椅的电脑。',
            next: '选好座椅，一起查看一下书架吧。',
            nextBtn: '下一题',
            nextId: '2',
        }, {
            type: 'answer',
            id: '1-3',
            pid: '1',
            imgUrl: q13image,
            name: '普通靠背椅',
            answer: '你选择的靠背椅让人想起了电影里大学校园的图书馆，坐在上面，学生开始想象自己上大学的样子。',
            next: '选好座椅，一起查看一下书架吧。',
            nextBtn: '下一题',
            nextId: '2',
        }, {
            type: 'answer',
            id: '1-4',
            pid: '1',
            imgUrl: q14image,
            name: '躺椅',
            answer: '躺椅的出现出乎所有人的预料，甚至有学生偷偷在体育课跑来空间补觉。',
            next: '选好座椅，一起查看一下书架吧。',
            nextBtn: '下一题',
            nextId: '2',
        }],
    },
    '2': {
        type: 'options',
        question: '查看书架时，你发现书架上有一本被翻烂了的书，它就是：',
        description: '(根据空间实际借阅榜单写答案)',
        options: [{
            type: 'answer',
            id: '2-1',
            pid: '2',
            imgUrl: q21image,
            name: '平凡的世界',
            answer: '这本书在空间的借阅榜单上排名前三！是当之无愧的霸榜选手。',
            next: '有学生陆续来到了这个空间，空间突然变得吵闹起来。',
            nextBtn: '下一题',
            nextId: '3',
        }, {
            type: 'answer',
            id: '2-2',
            pid: '2',
            imgUrl: q22image,
            name: '三体',
            answer: '高中生酷爱科幻，但由于本书长度非凡，第二部和第一部之间的借阅排行竟相差5名。',
            next: '有学生陆续来到了这个空间，空间突然变得吵闹起来。',
            nextBtn: '下一题',
            nextId: '3',
        }, {
            type: 'answer',
            id: '2-3',
            pid: '2',
            imgUrl: q23image,
            name: '房思琪的初恋乐园',
            answer: '许多同学借阅这本书只是因为标题里有初恋，但读完的同学通常会自发写出超长读后感以纾解复杂的情绪。',
            next: '有学生陆续来到了这个空间，空间突然变得吵闹起来。',
            nextBtn: '下一题',
            nextId: '3',
        }, {
            type: 'answer',
            id: '2-4',
            pid: '2',
            imgUrl: q24image,
            name: '数学之美',
            answer: '这本书应该不是被翻烂的，可能是不小心拿来垫过桌子，它有着非常可怜的借阅记录，也许对于大部分高中生来说，数学的美还是可望而不可即的吧。',
            next: '有学生陆续来到了这个空间，空间突然变得吵闹起来。',
            nextBtn: '下一题',
            nextId: '3',
        }],
    },
    '3': {
        type: 'options',
        question: '有同学想来这里说悄悄话，有同学想安静地看书，还有同学想要为下星期的辩论赛开一个小组会议，空间热闹非凡，但也有了噪音的问题，你打算怎么解决？',
        options: [{
            type: 'answer',
            id: '3-1',
            pid: '3',
            imgUrl: q31image,
            name: '设置隔音间，用于开会/讨论/聊天',
            answer: '空间建设预算加倍！但学生很喜欢这个设计方案，隔音间一度成为练歌房和乐器室。',
            next: '你总觉得空间有些暗，但出于用电安全的考虑，学校教学楼里几乎不设置开放的插座位置，你得想办法解决空间的照明问题。',
            nextBtn: '下一题',
            nextId: '4',
        }, {
            type: 'answer',
            id: '3-2',
            pid: '3',
            imgUrl: q32image,
            name: '划分使用时间，让不同活动分时段出现',
            answer: '虽然学生一开始觉得不方便，但很快就发现可以更有计划地安排自己到空间的活动。',
            next: '你总觉得空间有些暗，但出于用电安全的考虑，学校教学楼里几乎不设置开放的插座位置，你得想办法解决空间的照明问题。',
            nextBtn: '下一题',
            nextId: '4',
        }, {
            type: 'answer',
            id: '3-3',
            pid: '3',
            imgUrl: q33image,
            name: '设置空间使用规则，建议讨论/聊天在空间外进行',
            answer: '尽管总有打破规则的人，但空间的分贝确实降了下来，阅读的同学体验提升了许多。',
            next: '你总觉得空间有些暗，但出于用电安全的考虑，学校教学楼里几乎不设置开放的插座位置，你得想办法解决空间的照明问题。',
            nextBtn: '下一题',
            nextId: '4',
        }, {
            type: 'answer',
            id: '3-4',
            pid: '3',
            imgUrl: q34image,
            name: '坚持做开放空间，请使用者自己想出解决方案',
            answer: '学生们表示吵一点也没关系，完全不介意！就是要热闹！',
            next: '你总觉得空间有些暗，但出于用电安全的考虑，学校教学楼里几乎不设置开放的插座位置，你得想办法解决空间的照明问题。',
            nextBtn: '下一题',
            nextId: '4',
        }],
    },
    '4': {
        type: 'options',
        question: '你会如何提升空间采光和照明呢？',
        options: [{
            type: 'answer',
            id: '4-1',
            pid: '4',
            imgUrl: q41image,
            name: '更换窗户，使用透光性更好的大面积整装玻璃',
            answer: '真是敢想敢干的建筑师！谁不想要能眺望山景的超大玻璃窗呢！',
            next: '空间里是不是需要桌子呢？你开始思考这个问题。',
            nextBtn: '下一题',
            nextId: '5',
        }, {
            type: 'answer',
            id: '4-2',
            pid: '4',
            imgUrl: q42image,
            name: '更换顶灯，采用亮度更高的灯光',
            answer: '你很好地控制了预算，一招解决照明难题！',
            next: '空间里是不是需要桌子呢？你开始思考这个问题。',
            nextBtn: '下一题',
            nextId: '5',
        }, {
            type: 'answer',
            id: '4-3',
            pid: '4',
            imgUrl: q43image,
            name: '改变电路线路，增加落地灯和台灯',
            answer: '学生很喜欢这里丰富的光源，在台灯下阅读的感觉是住校生不常体会的，真的超级幸福！',
            next: '空间里是不是需要桌子呢？你开始思考这个问题。',
            nextBtn: '下一题',
            nextId: '5',
        }, {
            type: 'answer',
            id: '4-4',
            pid: '4',
            imgUrl: q44image,
            name: '以上都要！',
            answer: '为了平衡丰富的光源拼尽全力！尽管预算超标，但光线是一个空间体验的关键！',
            next: '空间里是不是需要桌子呢？你开始思考这个问题。',
            nextBtn: '下一题',
            nextId: '5',
        }],
    },
    '5': {
        type: 'options',
        question: '什么样的桌子可以让学生们觉得实用又便捷，放松又有趣呢？',
        options: [{
            type: 'answer',
            id: '5-1',
            pid: '5',
            imgUrl: q51image,
            name: '能放超多东西的巨长长条桌',
            answer: '学生把长条桌玩出了花样，甚至拿它打起了乒乓球。',
            nextBtn: '查看结果',
        }, {
            type: 'answer',
            id: '5-2',
            pid: '5',
            imgUrl: q52image,
            name: '像七巧板一样可以随意拼接的桌子',
            answer: '谁能拼出最多花样成了学生之间暗暗较劲的小游戏。',
            nextBtn: '查看结果',
        }, {
            type: 'answer',
            id: '5-3',
            pid: '5',
            imgUrl: q53image,
            name: '嵌入墙壁的无腿桌，下方可以当做储物或“自闭”空间',
            answer: '学生最喜欢窝在桌下的空间看书，桌面上方的墙面也被大家贴上了各种装饰画。',
            nextBtn: '查看结果',
        }, {
            type: 'answer',
            id: '5-4',
            pid: '5',
            imgUrl: q54image,
            name: '可以像叠叠乐一样堆起来的桌子',
            answer: '只要把桌子叠起来放在一边，就可以在空间里开音乐会啦！',
            nextBtn: '查看结果',
        }],
    },
    'end-1': {
        type: 'end',
        title: '用户挚友',
        description: '在你的设计中体现了开放、包容的原则，充分尊重了使用者的习惯。',
        imgUrl: a1image,
        peerspace: '这是一个真实存在的空间——建设在县域中学里的PEER空间，硬件只是空间的一部分，更多学生成长的支持项目在这里展开。99公益日，请支持PEER空间的运营，为县域中学生的全面发展撑起一片天地。',
    },
    'end-2': {
        type: 'end',
        title: '质感营造师',
        description: '你的设计充分考虑到了空间整体质感，让各部分功能可以奇妙地结合在一起。',
        imgUrl: a2image,
        peerspace: '这是一个真实存在的空间——建设在县域中学里的PEER空间，硬件只是空间的一部分，更多学生成长的支持项目在这里展开。99公益日，请支持PEER空间的运营，为县域中学生的全面发展撑起一片天地。',
    },
    'end-3': {
        type: 'end',
        title: '惊喜创造家',
        description: '你的设计巧思让空间充满惊喜，不同的打开方式提升了使用者的体验感。',
        imgUrl: a3image,
        peerspace: '这是一个真实存在的空间——建设在县域中学里的PEER空间，硬件只是空间的一部分，更多学生成长的支持项目在这里展开。99公益日，请支持PEER空间的运营，为县域中学生的全面发展撑起一片天地。',
    },
    'end-4': {
        type: 'end',
        title: '空间培育者',
        description: '你设计的空间是可以自由生长的空间，你为使用者留出了探索和共创的环境。',
        imgUrl: a4image,
        peerspace: '这是一个真实存在的空间——建设在县域中学里的PEER空间，硬件只是空间的一部分，更多学生成长的支持项目在这里展开。99公益日，请支持PEER空间的运营，为县域中学生的全面发展撑起一片天地。',
    },
}

export const endlist = [{
    conditions: [['3-1', '5-3'], ['3-2', '5-2'], ['3-3', '5-4'], ['3-4', '5-1']],
    id: 'end-1',
}, {
    conditions: [['3-1', '5-1'], ['3-2', '5-4'], ['3-3', '5-2'], ['3-4', '5-3']],
    id: 'end-2',
}, {
    conditions: [['3-4', '5-4'], ['3-1', '5-2'], ['3-1', '5-4'], ['3-4', '5-2']],
    id: 'end-3',
}, {
    conditions: [['3-2', '5-1'], ['3-2', '5-3'], ['3-3', '5-1'], ['3-3', '5-3']],
    id: 'end-4',
}]

