import React, { useState, useEffect } from 'react'
import peermusic from '@/assets/audio/peer.mp3'

const music = new Audio(peermusic)
music.loop = true

const MainLayout = (props: any) => {
    const { children } = props
    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        play()
    }, [])

    const onToggleAudio = () => {
        const newPlay = !playing
        setPlaying(newPlay)
        if (newPlay) {
            music.play()
        } else {
            music.pause()
        }
    }

    const play = () => {
        if (window.WeixinJSBridge) {
            window.WeixinJSBridge.invoke('getNetworkType', {}, () => {
                setTimeout(() => {
                    setPlaying(true)
                    music.play()
                }, 300)
            })
        } else {
            document.addEventListener('WeixinJSBridgeReady', () => {
                window.WeixinJSBridge.invoke('getNetworkType', {}, () => {
                    setTimeout(() => {
                        setPlaying(true)
                        music.play()
                    }, 300)
                })
            }, false)
        }
    }

    return <div className="mainLayout">
        <div className="mainLayout-header">
            {playing
                ? <span className="mainLayout-header-icon open" onClick={onToggleAudio} />
                : <span className="mainLayout-header-icon close" onClick={onToggleAudio} />
            }
        </div>
        <div className="mainLayout-cont">
            {children}
        </div>
    </div>
}

export default MainLayout
