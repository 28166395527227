import React from 'react'
import ReactDOM from 'react-dom'
import '@/assets/styles/index.less'
import { ConfigProvider } from 'antd-mobile'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import { RecoilRoot } from 'recoil'
import { DebugObserver } from '@/store/debug'
import App from './App'
import reportWebVitals from './reportWebVitals'

// import VConsole from 'vconsole'
// if (process.env.REACT_APP_ENV === 'dev') {
//     const con = new VConsole()
//     console.log('vconsole: ', con)
// }

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <RecoilRoot>
            <DebugObserver />
            <App />
        </RecoilRoot>
    </ConfigProvider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
