import React from 'react'
import { Button } from 'antd-mobile'
import { useRecoilValue } from 'recoil'
import { userInfo } from '@/store/atom'

interface IData {
    type?: string,
    condition?: any[],
    title?: string,
    description?: string,
    imgUrl?: string,
    peerspace?: string,
}

interface IProps {
    data?: IData;
    onCheckProject?: Function;
    onAgain?: Function;
    onDownload?: Function;
    onShare?: Function;
}

const CardEnd = (props: IProps) => {
    const { data, onCheckProject, onAgain, onDownload, onShare } = props
    const user = useRecoilValue(userInfo)

    return (
        <div className="end">
            <div className="end-header">
                <div className="end-header-name">{user?.userName}</div>
                <div className="end-header-line"></div>
                <div>你是</div>
                <div>“{data?.title}”建筑师！</div>
            </div>
            <div className="end-desc">{data?.description}</div>
            <div className="end-card">
                <img className="end-card-img" src={data?.imgUrl} alt="" />
                <Button block onClick={() => onCheckProject?.()}>查看项目页面</Button>
                <div className="end-card-peerspace">{data?.peerspace}</div>
            </div>
            <div className="end-btn">
                <Button block onClick={() => onAgain?.()}>再测一次</Button>
                <span className="end-btn-icon downloadtype" onClick={() => onDownload?.(data)}>
                    <div className="end-btn-icon-text">保存结果</div>
                </span>
                <span className="end-btn-icon sharetype" onClick={() => onShare?.()}>
                    <div className="end-btn-icon-text">分享</div>
                </span>
            </div>
        </div>
    )
}

export default CardEnd
