import React, { useEffect, useRef, useState } from 'react'
import { Mask, DotLoading } from 'antd-mobile'
import html2canvas from 'html2canvas'
import { useRecoilValue } from 'recoil'
import { userInfo } from '@/store/atom'

interface IProps {
    visible?: boolean;
    data?: any;
    onClick?: Function;
}

const Share = (props: IProps) => {
    const { visible, data, onClick } = props
    const [url, setUrl] = useState('')
    const ref = useRef(null)
    const user = useRecoilValue(userInfo)

    useEffect(() => {
        if (!visible) {
            setUrl(url)
        }
    }, [visible])

    useEffect(() => {
        if (!visible) { return }
        if (!data) { return }
        setTimeout(() => {
            genPng()
        }, 800)
    }, [data, visible])

    const genPng = () => {
        const node = ref?.current
        if (!node) { return }
        html2canvas(node).then((canvas: any) => {
            const url = canvas.toDataURL('image/png')
            setUrl(url)
        })
    }

    return (
        <Mask visible={visible} onMaskClick={() => onClick?.()}>
            <div className="download" onClick={() => onClick?.()}>
                {url
                    ? <img src={url} alt="" className="download-img" />
                    : (
                        <div className="download-wrapper">
                            <div className="download-loading">
                                <DotLoading />
                            </div>
                            <div ref={ref} className="download-template">
                                <div className="download-template-title">测测你是什么风格的</div>
                                <div className="download-template-title">“空间”建筑师</div>
                                <img className="download-template-img" src={data?.imgUrl} alt="" />
                                <div className="download-template-name">{user?.userName}</div>
                                <div className="download-template-builder">你是“{data?.title}”建筑师！</div>
                                <div className="download-template-desc">{data?.description}</div>
                                <div className="download-template-footer">
                                    <span className="download-template-footer-img ps" />
                                    <span className="download-template-footer-img qr" />
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="download-hint">长按保存图片</div>
            </div>
        </Mask>
    )
}

export default Share
