import React from 'react'
import { Mask } from 'antd-mobile'

interface IProps {
    visible?: boolean;
    onClick?: Function;
}

const Share = (props: IProps) => {
    const { visible, onClick } = props

    return (
        <Mask visible={visible} onMaskClick={() => onClick?.()}>
            <div className="share" />
        </Mask>
    )
}

export default Share
