import React, { useState } from 'react'
import isEqual from 'lodash/isEqual'
import CardStart from './CardStart'
import CardOptions from './CardOptions'
import CardAnswer from './CardAnswer'
import CardEnd from './CardEnd'
import ShareMask from './Share'
import Download from './Download'

import { config, endlist } from './config'

let answers: string[] = []

interface EndItem {
    conditions: string[][];
    id: string;
}

const Home = (props: any) => {
    const [data, setData] = useState<any>(config.start)
    const [isSharing, setIsSharing] = useState(false)
    const [isDownload, setIsDownload] = useState(false)
    const [downloadData, setDownloadData] = useState(null)

    const onDataChange = (e: any) => {
        if (!e) { return }
        if (e.nextId) {
            setData(config[e.nextId])
        } else {
            let endId = ''
            endlist.forEach((e: EndItem) => {
                e.conditions.forEach((c: string[]) => {
                    if (isEqual(answers, c)) {
                        endId = e.id
                        return
                    }
                })
            })
            setData(config[endId])
        }
    }

    const onSubmit = (e: any) => {
        if (e?.pid === '3' || e?.pid === '5') {
            answers.push(e.id)
        }
        setData(e)
    }

    const onOptionChange = (e: any) => {
        console.log(e, 'option change')
    }

    const onCheckProject = () => {
        window.location.href = 'https://ssl.gongyi.qq.com/m/weixin/detail.htm?pid=224801&gt=pcate'
    }

    const onAgain = () => {
        answers = []
        setData(config.start)
    }

    const map = {
        start: <CardStart data={data} onClick={onDataChange} />,
        options: <CardOptions data={data} onSubmit={onSubmit} onOptionChange={onOptionChange} />,
        answer: <CardAnswer data={data} onClick={onDataChange} />,
        end: (
            <CardEnd
                data={data}
                onCheckProject={onCheckProject}
                onAgain={onAgain}
                onShare={() => setIsSharing(true)}
                onDownload={(data: any) => {
                    setDownloadData(data)
                    setIsDownload(true)
                }}
            />
        ),
    }

    return (
        <div className="home">
            {data?.type && map[data.type]}
            <ShareMask visible={isSharing} onClick={() => setIsSharing(false)} />
            <Download
                visible={isDownload}
                data={downloadData}
                onClick={() => {
                    setIsDownload(false)
                    setDownloadData(null)
                }}
            />
        </div>
    )
}

export default Home