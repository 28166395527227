// 公用的文本
const common = {
    select: '选择',
    search: '查询',
    add: '新增',
    save: '保存',
    create: '创建',
    cancel: '取消',
    confirm: '确定',
    edit: '编辑',
    develop: '开发',
    release: '发布',
    operation: '运行',
    offline: '下架',
    delete: '删除',
    view: '查看',
    export: '导出',
    details: '详情',
    arrange: '编排',
    saveSuccess: '保存成功',
    saveFailure: '保存失败',
    updateSuccess: '修改成功',
    updateFailure: '修改失败',
    deleteSuccess: '删除成功',
    deleteFailure: '删除失败',
    removeSuccess: '移除成功',
    removeFailure: '移除失败',
    devSuccess: '开发成功',
    devFailure: '开发失败',
    runSuccess: '运行成功',
    runFailure: '运行失败',
    deleteRemind: '删除后将不能进行恢复，请确认是否删除？',
    submitSuccess: '提交成功',
    submitFailure: '提交失败',
    addSuccess: '添加成功',
    addFailure: '添加失败',
    bindSuccess: '绑定成功',
    bindFailure: '绑定失败',
    createSuccess: '创建成功',
    releaseSuccess: '发布成功',
    releaseFailure: '发布失败',
    offlineSuccess: '下架成功',
    offlineFailure: '下架失败',
    removeConfirm: '确认移除',
    deleteConfirm: '确认删除',
    releaseConfirm: '确认发布',
    offlineConfirm: '确认下架',
    version: '版本',
    rely: '依赖',
    shared: '已共享',
    or: '或者',
    nextStep: '下一步',
    prevStep: '上一步',
};

export default common