import React from 'react'
import { Button } from 'antd-mobile'

interface IAnswer {
    type?: string,
    id?: string,
    imgUrl?: string,
    name?: string,
    answer?: string,
    next?: string,
    nextBtn?: string,
    nextId?: string,
}

interface IProps {
    data?: IAnswer;
    onClick?: Function;
}

const CardAnswer = (props: IProps) => {
    const { data, onClick } = props

    return (
        <div className="answer">
            <div className="answer-card">
                <img className="answer-card-l" src={data?.imgUrl} alt="" />
                <div className="answer-card-r">
                    <div className="answer-card-name">{data?.name}</div>
                    <div className="answer-card-answer">{data?.answer}</div>
                </div>
            </div>
            <div className="answer-next">{data?.next}</div>
            <Button block onClick={() => onClick?.(data)}>{data?.nextBtn}</Button>
        </div>
    )
}

export default CardAnswer
