import React, { useRef } from 'react'
import { useRecoilState } from 'recoil'
import { Button, Input, Toast } from 'antd-mobile'
import { userInfo } from '@/store/atom/index'

interface IData {
    type?: string;
    question?: string;
    imgUrl?: string;
    cont?: string;
    nextBtn?: string;
    nextId?: string;
}

interface IProps {
    data?: IData;
    onClick?: Function;
}

const CardStart = (props: IProps) => {
    const { data, onClick } = props
    const [user, setUser] = useRecoilState(userInfo)
    const inputRef = useRef(null)

    const onNext = () => {
        if (!user?.userName) {
            Toast.show({
                content: '请您填写名字',
            })
            return
        }
        onClick?.(data)
    }

    return (
        <div className="start">
            <div className="title">{data?.question}</div>
            <div className="start-card">
                <img className="start-img" src={data?.imgUrl} alt="" />
                <div className="start-cont">{data?.cont}</div>
            </div>
            <div className="start-input">
                <Input
                    ref={inputRef}
                    placeholder="您的名字"
                    maxLength={20}
                    defaultValue={user?.userName}
                    onChange={(val: string) => {
                        setUser({ userName: val })
                    }}
                />
            </div>
            <Button block onClick={onNext}>
                {data?.nextBtn}
            </Button>
        </div>
    )
}

export default CardStart